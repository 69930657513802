
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { priceRules } from "@/utils/verify";
import t from "@common/src/i18n/t";
import { emptyToLine } from "../../utils/common";
const management = namespace("management");
const marketing = namespace("marketing");
const base = namespace("base");
@Component({
  methods: { emptyToLine },
  components:{
  }
})
export default class ValueCardEdit extends Vue {
  @base.Action getDictionaryList;
  @marketing.Action createCardTemplate;
  @marketing.Action queryCardTemplateDetail;
  @marketing.Action updateCardTemplate;
  @management.Action getAllStoreList;
  @management.Action getStoreList
  @management.Action getStoreCouponList

  valueCardForm = {
    cardTemplateName: "",
    cardAmount: "",
    giftAmount: "",
    enableStatus: 1,
    checked: true,
    usefulLife: t("marketing.permanent"),
    validPeriod: -1,
    cardDesc: "",
    cardType: 2,
    cardTemplateCode: "",
    carryingAmount: "",
    usedStatus: undefined,
    applicableStores:[],
    thirdMapList: [],
    giftDiscountCode: '',
    giftDiscountName: '',
    giftWay: '1'

  };

  definitionGroupData: any = {
    source: null,
    thirdTicketId: ""
  };

  groupChannel: any = [
    {value: 2,label: "抖音"},
    {value: 3,label: "美团"},
    {value: 7,label: "快手"},
  ];
  storeList: any=[];

  showCouponPopup: boolean = false;

  moneyRule = (rule, value, callback) => {
    if (priceRules(value)) {
      callback();
    } else {
      callback(new Error(t("v210831.please-enter-a-rational-amount")));
    }
  };
  valueCardRules = {
    cardTemplateName: [
      { required: true, message: t("marketing.please-input-card-name"), trigger: "blur" },
      { min: 1, max: 150, message: t("v210831.the-length-is-1-150"), trigger: "blur" }
    ],
    cardAmount: [
      { required: true, message: t("v210831.please-enter-the-recharge-amount"), trigger: "blur" },
      {
        validator: this.moneyRule
      }
    ],
    giftAmount: [
      // { required: true, message: t("v210831.please-enter-the-give-amount"), trigger: "blur" },
      {
        validator: this.moneyRule
      }
    ]
  };

  handleGiftCoupon(){
    this.searchCouponStore();
    this.showCouponPopup = true;
  }
  giftWayChange(ev){
    this.valueCardForm.giftWay = ev;
    if(this.valueCardForm.giftWay == '1'){
      this.valueCardForm.giftDiscountCode = '';
      this.valueCardForm.giftDiscountName = '';
    }else{
      // this.computeCountMoney = Number(this.valueCardForm.cardAmount);
      this.valueCardForm.giftAmount = '';
    }
  }
  // 优惠券选择
  handleSelectedStoreCoupon() {
    this.mix_pageNum = 1;
    this.valueCardForm.giftDiscountCode = this.selectedRowCoupon.activityCode;
    this.valueCardForm.giftDiscountName = this.selectedRowCoupon.activityName;
    this.showCouponPopup = false;
  }
  handleSearchCoupon(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.storeCouponListFn();
  }

  selectedRowCoupon: any = {};

  handleRowSelectCoupon(val){
    this.selectedRowCoupon = val;
  }

  handleRestCoupon(){
    this.selectedRowCoupon = {};
    this.searchForm.storeCode = ""
  }

  coupon_total: number = 0;
  coupon_pageNum: number = 1;
  coupon_pageSize: number = 10;

  couponSizeChange(e){
    this.coupon_pageSize = e;
    this.storeCouponListFn();

  }
  couponPageChange(e){
    this.coupon_pageNum = e;
    this.storeCouponListFn();
  }
  storeCouponList: any = [];

  // 获取优惠券列表
  storeCouponListFn(){
    this.mix_pageNum = 1;
    if(!this.searchForm.storeCode){
      this.$message.error("请先选择门店")
      return
    }
    this.getStoreCouponList({
      storeCode: this.searchForm.storeCode,
      pageNum: this.coupon_pageNum,
      pageSize: this.coupon_pageSize
    }).then(res => {
      this.storeCouponList = res.data.list;
      this.coupon_total = res.data.total;
    })
  }

  get couponSearch() {
    return [
      {
        label: "选择门店",
        type: "select-search",
        value: this.searchForm.storeCode,
        prop: "storeCode",
        placeholder: "请选择门店",
        selectList: this.storeList,
        searchFun: this.searchCouponStore
      }
    ];
  }

  searchForm = {
    cardTemplateName: "",
    enableStatus: 1, //（1：启用、0：禁用） 只显示启用的卡
    storeCode: ''
    // cardType: 1 //（1：套餐卡、2：储蓄卡） 0630这个版本先写死
  };

  // 获取门店列表
  searchCouponStore(val = ''){
    console.log(val)
    this.getStoreList({
      storeTypeList: ['3', '8', '9'],
      pageNum: 1,
      pageSize: 20,
      storeName: val
    }).then(res => {
      this.storeList = res.data.list ? res.data.list.map(item => {
          item.label = item.storeName;
          item.value = item.storeCode;
          return item;
        })
        : [];
      this.searchForm.storeCode = this.storeList[0].storeCode
      this.storeCouponListFn();
    })
  }

  //计算属性
  get computeCountMoney() {
    return Number(this.valueCardForm.cardAmount) + Number(this.valueCardForm.giftAmount);
  }
  get isAdd() {
    return this.$route.name === "ValueCardAdd";
  }
  get breadData() {
    return [
      { name: t("marketing.card-template"), path: "/marketing/card-template" },
      {
        name: this.isAdd ? t("v210831.new-stored-value-card-template") : t("v210831.edit-stored-value-card-template")
      }
    ];
  }
  created() {
    this.init();
  }
  mounted() {

  }

  init() {
    if (this.$route.name === "ValueCardEdit") {
      this.queryCardTemplateDetail({ cardTemplateCode: this.$route.params.id }).then(res => {
        this.valueCardForm.cardAmount = res.data.cardAmount;
        this.valueCardForm.cardDesc = res.data.cardDesc;
        this.valueCardForm.cardTemplateCode = res.data.cardTemplateCode;
        this.valueCardForm.cardTemplateName = res.data.cardTemplateName;
        this.valueCardForm.carryingAmount = res.data.carryingAmount;
        this.valueCardForm.enableStatus = res.data.enableStatus;
        this.valueCardForm.giftAmount = res.data.giftAmount;
        this.valueCardForm.usedStatus = res.data.usedStatus;

        this.valueCardForm.giftWay = res.data.giftDiscountCode ? "2" : "1";
        this.valueCardForm.giftDiscountCode = res.data.giftDiscountCode;
        this.valueCardForm.giftDiscountName = res.data.giftDiscountName;

        this.storeList = res.data.storesList
          ? res.data.storesList.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
          : []

        this.valueCardForm.applicableStores = res.data.applicableStores;

        if(res.data.thirdMapList == null){
          res.data.thirdMapList = []
        }
        this.valueCardForm.thirdMapList = res.data.thirdMapList;
      });
    }
  }

  searchStore(query) {
    this.getAllStoreList({
      pageNum: 1,
      pageSize: 10,
      searchKey: query
    }).then(data => {
      this.storeList = data.data.list
        ? data.data.list.map(item => {
          item.label = item.storeName;
          item.value = item.storeCode;
          return item;
        })
        : []
    })
  }

  deleteItem(key, index) {
    if(key === "thirdMapList"){
      this.valueCardForm[key].splice(index, 1);
      return
    }
  }

  handleAddGroup() {
    this.valueCardForm.thirdMapList.push(Object.assign({}, this.definitionGroupData));
  }

  submitForm() {
    let resForm = this.$refs.valueCardForm as any;
    resForm.validate(valid => {
      if (valid) {
        if (this.$route.name === "ValueCardEdit") {
          // alert("submit!");
          this.updateCardTemplate(this.valueCardForm).then(res => {
            console.log("[ res ]", res);
            this.$message.success(t("v210831.card-template-updated"));
            this.$router.push("/management/card-template");
          });
        } else {
          this.createCardTemplate(this.valueCardForm).then(res => {
            console.log("[ res ]", res);
            this.$message.success(t("v210831.card-template-saved"));
            this.$router.push("/management/card-template");
          });
        }
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
}
